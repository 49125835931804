import React from 'react'
import { Link } from 'react-router-dom'
import Services from '../../api/service'

const Footer = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <footer className="wpo-site-footer">
            <div className="wpo-upper-footer">
                <div className="container">
                    <div className="row">
                        <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
                            <div className="widget about-widget">
                                <div className="logo widget-title">
                                    {/* <img src={Logo} alt="footer" /> */}
                                </div>
                                <p>Jaco Cocal Beach House.</p>
                                <ul>
                                {/* <li><a href="https://www.facebook.com/gallinae.palo/"><i className="fi flaticon-facebook-app-symbol"></i></a></li> */}
                                    {/* <li>
                                        <Link onClick={ClickHandler} to="/">
                                            <i className="ti-twitter-alt"></i>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link onClick={ClickHandler} to="/">
                                            <i className="ti-instagram"></i>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link onClick={ClickHandler} to="/">
                                            <i className="ti-google"></i>
                                        </Link>
                                    </li> */}
                                </ul>
                            </div>
                        </div>
                        <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
                            <div className="widget link-widget s1">
                                <div className="widget-title">
                                    <h3>Services</h3>
                                </div>
                                <ul>
                                    {Services.slice(0,5).map((service, sitem) => (
                                        <li key={sitem}><p>{service.title}</p></li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
                           
                        </div>

                        <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
                            <div className="widget wpo-service-link-widget">
                                <div className="widget-title">
                                    <h3>Contact </h3>
                                </div>
                                <div className="contact-ft">
                                    <ul>
                                        <li><i className="fi flaticon-placeholder"></i>Jaco Beach, Calle Cocal, Costa Rica</li>
                                        <li><i className="fi flaticon-phone-call"></i>+506 8400 1914</li>
                                        <li><i className="fi flaticon-send"></i>gallinaepalobar@gmail.com</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="wpo-lower-footer">
                <div className="container">
                    <div className="row">
                        <div className="col col-xs-12">
                            <p >Other Local taxes and Hotel fees may be collected directly by the hotel. All sales are final and non-refundable.</p>
                            <p className="copyright"> Copyright &copy; 2024 Gallina E Palo.
                                All Rights Reserved.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="wpo-lower-footer">
                <div className="container">
                    <div className="row">
                        <div className="col col-xs-12">
                        <p className="copyright">
                                Made with  &#10084; by <a href="https://irule.center" target="_blank" rel="noopener noreferrer">irule.center</a>
                            </p>                       
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;